import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Té un capell de fins a 10 cm de diàmetre. Primer hemisfèric per acabar pla i amb esquames fibril·loses de color marró ocre. Les làmines són lliures, pròximes al peu i de color rosa grisenc que canvia a negre amb el temps i amb l’aresta blanquinosa. Les espores són marrons porpres en massa, el·líptiques, de 5-7 x 3-4 micres. El peu dur un anell senzill, ample i blanc, és llarg i no molt gruixut amb la base bulbosa. És blanc i torna vermell quan el toquem.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      